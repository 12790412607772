import { z } from 'zod';

export enum Environment {
  local = 'local',
  ci = 'ci',
  prod = 'production',
  test = 'test',
}

export enum LoggerType {
  simple = 'simple',
  secured = 'secured',
}

export enum ClientLoggerType {
  console = 'console',
  prod = 'prod',
}

export enum LogLevel {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export enum NodeEnv {
  production = 'production',
  test = 'test',
  development = 'development',
  cli = 'cli',
}

const environment = z.nativeEnum(Environment);
const logLevel = z.nativeEnum(LogLevel);

const strictBooleanSchema = z.preprocess(
  (value) =>
    z
      .enum(['true', 'false'])
      .transform((enumValue) => JSON.parse(enumValue))
      .parse(value),
  z.boolean(),
);

export const serverSideSchema = z.object({
  NODE_ENV: z.nativeEnum(NodeEnv),
  NEXT_PUBLIC_ENVIRONMENT: environment,
  NEXT_PUBLIC_LOG_LEVEL: logLevel,
  LOGGER: z.nativeEnum(LoggerType),
  DATABASE_URL: z.string(),
  PASSWORD_PEPPER_BASE64: z.string(),
  GOOGLE_AUTH_PROVIDER_CLIENT_ID: z.string().optional(),
  GOOGLE_AUTH_PROVIDER_CLIENT_SECRET: z.string().optional(),
  MOCK_EMAIL_CLIENT: strictBooleanSchema,
  SENDER_EMAIL: z.string().email(),
  APP_URL: z.string(),
  SESSION_COOKIE_NAME: z.string(),
  SESSION_EXPIRATION_INTERVAL: z.coerce.number(),
  LOCKOUT_THRESHOLD: z.coerce.number(),
  OBSERVABLE_WINDOW: z.coerce.number(),
  LOCKOUT_DURATION: z.coerce.number(),
  PASSWORD_RESET_TOKEN_EXPIRATION_INTERVAL: z.coerce.number(),
  EMAIL_VALIDATION_TOKEN_EXPIRATION_INTERVAL: z.coerce.number(),
  EMAIL_INTERVAL: z.coerce.number(),
  PASSWORD_RESET_INTERVAL: z.coerce.number(),
  TOTP_APP_NAME: z.string(),
  TOTP_ENCRYPTION_SECRET: z.string().optional(),
  MFA_EMAIL_USAGE_WINDOW: z.coerce.number(),
  NEXT_PUBLIC_MFA_CODE_LENGTH: z.coerce.number(),
  MFA_RATE_LIMIT_THRESHOLD: z.coerce.number(),
  MFA_BASE_RATE_LIMIT: z.coerce.number(),
  MFA_NUMBER_OF_RECOVERY_CODES: z.coerce.number(),
  SECURE_COOKIE: strictBooleanSchema,
  CSP_ENABLE_SCRIPT_UNSAFE_EVAL: strictBooleanSchema,
  CSP_DISABLE_UPGRADE_INSECURE_REQUEST: strictBooleanSchema,
  API_URL: z.string(),
  API_KEY: z.string(),
  SUPERADMIN_API_KEY: z.string(),
});

export const clientSideSchema = z
  .object({
    NEXT_PUBLIC_ENVIRONMENT: environment,
    NEXT_PUBLIC_LOG_LEVEL: logLevel,
    NEXT_PUBLIC_LOGGER: z.nativeEnum(ClientLoggerType),
    NEXT_PUBLIC_SENTRY_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
    NEXT_PUBLIC_MFA_CODE_LENGTH: z.coerce.number(),
  })
  .refine(
    (env) => {
      if (env.NEXT_PUBLIC_SENTRY_ENABLED && !env.NEXT_PUBLIC_SENTRY_DSN) {
        return false;
      }
      return true;
    },
    {
      message:
        'NEXT_PUBLIC_SENTRY_DSN should be defined if NEXT_PUBLIC_SENTRY_ENABLED is true',
      path: ['NEXT_PUBLIC_SENTRY_DSN'],
    },
  );
